import React from "react"
import styled from "styled-components"
import { MAIN_COLOR } from "../variables"

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 1.4em;
  width: 1.4em;
  background-color: #d7d8d7;
  border: solid 1px #bcbdbc;
  border-radius: 4px;

  :after {
    content: "";
    position: absolute;
    display: none;
  }

  :after {
    left: 0.45em;
    top: 0.1em;
    width: 0.4545em;
    height: 0.8182em;
    border: solid white;
    border-width: 0px 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const CheckboxContainer = styled.label`
  display: block;
  position: relative;
  padding-left: 1.59em;
  margin-bottom: 0.5454em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  :hover input ~ ${Checkmark} {
    background-color: #ccc;
  }

  input:checked ~ ${Checkmark} {
    background-color: ${MAIN_COLOR};
  }

  input:checked ~ ${Checkmark}:after {
    display: block;
  }

  .child-container {
    padding-left: 1rem;
    font-size: 0.8181em;
    text-align: left;
  }
`

const Error = styled.span`
  color: red;
  font-size: 0.7272em;
`

const CheckboxInput = ({
  className,
  checked,
  onChange,
  children,
  error,
  ...rest
}) => {
  return (
    <CheckboxContainer className={className}>
      <input type="checkbox" checked={checked} onChange={onChange} {...rest} />
      <Checkmark checked={checked} />
      <div className="child-container">{children}</div>
      {error && <Error>{error}</Error>}
    </CheckboxContainer>
  )
}

export default CheckboxInput
