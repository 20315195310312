import React from "react";
import styled from "styled-components";
import { MAIN_COLOR } from "../variables";

const FormGroupContainer = styled.div`
  input, select, textarea {
    width: 100%;
    color: ${MAIN_COLOR};

    &:focus {
      outline: none;
    }
  }
`

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.4545em;
  font-size: 0.8181em;
  font-weight: 400;
`

const FormGroup = ({ labelText, className, children }) => {
  return (
    <FormGroupContainer className={className || 'wow fadeIn'}>
      <StyledLabel>{labelText}</StyledLabel>
      {children}
    </FormGroupContainer>
  )
}

export default FormGroup;
