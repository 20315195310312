import React, { useState } from "react"
import styled from "styled-components"
import { postDonation } from "../../services/api/donation"
import { SECONDARY_COLOR } from "../../variables"
import CheckboxInput from "../CheckboxInput"
import Container from "../Container"
import FormGroup from "../FormGroup"
import TextInput from "../TextInput"

const DonationFormContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.8181rem;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;

    .grid-span {
      grid-column: span 2;
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  grid-column: span 2;
`

const SubmitButton = styled.input`
  font-size: 1.0909em;
  font-weight: 700;
  background-color: ${SECONDARY_COLOR};
  color: #fff;
  padding: 15px 40px;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border: none;
  border-radius: 10px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: #f0ce8a;
    box-shadow: 0 2px 14px 0 #d2b6ab;
  }
`

const MessageParagraph = styled.p`
  color: green;
`

const StyledLink = styled.a`
  color: inherit;
  transition: color 0.2s ease-in-out;

  :hover {
    color: ${SECONDARY_COLOR};
  }
`

const Error = styled.span`
  color: red;
  font-size: 0.7272em;
`

const CheckboxSmallText = styled.small`
  margin-top: 0.25em;
  display: block;
`

const initialFormState = {
  donator_name: "",
  donator_email: "",
  donator_phone: "",
  gdpr: false,
  tos: false,
  public_donator: false,
}

const DonationForm = ({ quantity, unit_price = 1000 }) => {
  const [values, setValues] = useState({
    ...initialFormState,
    quantity,
    unit_price,
  })
  const [message, setMessage] = useState("")
  const [errors, setErrors] = useState({})

  const updateFormValue = key => value => {
    setValues({ ...values, [key]: value })
    setErrors({ ...errors, [key]: "" })
  }

  async function onSubmit() {
    if (!values.tos)
      return setErrors({
        ...errors,
        tos: "Az általános szerződési feltételek elfogadása kötelező!",
      })
    if (!values.gdpr)
      return setErrors({
        ...errors,
        gdpr: "Az adatvédelmi nyilatkozat elfogadása kötelező!",
      })
    if (values.quantity >= 1) {
      setMessage("")

      try {
        setErrors({})
        const result = await postDonation(values)
        if (result.data.redirect) {
          window.location.href = result.data.redirect
        } else {
          setMessage(result.data.paymentMessage);
        }
      } catch (error) {
        const { response } = error
        if (response && response.data) {
          if (response.status === 422) {
            setErrors(response.data)
          }
        }
        console.error(error)
      }
    } else {
      setErrors({
        ...errors,
        quantity: "A felajánlásnak többnek kell lennie mint 0!",
      })
    }
  }

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        onSubmit()
      }}
    >
      {errors["quantity"] && <Error>{errors["quantity"]}</Error>}
      <DonationFormContainer>
        <FormGroup labelText="Név*">
          <TextInput
            onChange={event =>
              updateFormValue("donator_name")(event.target.value)
            }
            value={values["donator_name"]}
            error={errors["donator_name"]}
            required
          ></TextInput>
        </FormGroup>
        <FormGroup labelText="Email*">
          <TextInput
            onChange={event =>
              updateFormValue("donator_email")(event.target.value)
            }
            value={values["donator_email"]}
            error={errors["donator_email"]}
            type="email"
            required
          ></TextInput>
        </FormGroup>
        <FormGroup labelText="Telefon">
          <TextInput
            onChange={event =>
              updateFormValue("donator_phone")(event.target.value)
            }
            value={values["donator_phone"]}
            error={errors["donator_phone"]}
            type="tel"
          ></TextInput>
        </FormGroup>
        <CheckboxInput
          className="grid-span wow fadeIn"
          onChange={event => updateFormValue("tos")(event.target.checked)}
          error={errors["tos"]}
          checked={values["tos"]}
        >
          Elfogadom az{" "}
          <StyledLink
            href="/altalanos-szerzodesi-feltetelek"
            target="_blank"
            rel="noopener noreferrer"
          >
            általános szerződési feltételekt
          </StyledLink>
        </CheckboxInput>
        <CheckboxInput
          className="grid-span wow fadeIn"
          onChange={event => updateFormValue("gdpr")(event.target.checked)}
          error={errors["gdpr"]}
          checked={values["gdpr"]}
        >
          Elfogadom az{" "}
          <StyledLink
            href="/adatvedelmi-nyilatkozat"
            target="_blank"
            rel="noopener noreferrer"
          >
            adatvédelmi nyilatkozatot
          </StyledLink>
        </CheckboxInput>
        <CheckboxInput
          className="grid-span wow fadeIn"
          onChange={event =>
            updateFormValue("public_donator")(event.target.checked)
          }
          checked={values["public_donator"]}
        >
          Publikus adományozó vagyok
          <CheckboxSmallText>
            Ha bepipálod, hogy publikus adományozó vagy, azzal hozzájárulsz, hogy
          megjelenjen a neved a facebook oldalon a támogatoink között egy poszt formájában.
          </CheckboxSmallText>
        </CheckboxInput>

        {message && (
          <MessageParagraph className="grid-span">{message}</MessageParagraph>
        )}

        <ButtonContainer>
          <SubmitButton type="submit" value="Bankkártyás fizetés Barionnal" />
        </ButtonContainer>
      </DonationFormContainer>
    </form>
  )
}

export default DonationForm
