import React from 'react'
import styled from "styled-components"
import { ERROR_COLOR, SECONDARY_COLOR } from "../variables"

const Input = styled.input`
  height: 2.2727em;
  padding: 0.4545rem;
  border: 2px solid ${SECONDARY_COLOR};
  font-size: 0.8181em;

  &.error {
    border: 2px solid ${ERROR_COLOR};
  }
`

const Error = styled.span`
  color: red;
  font-size: 0.7272em;
`

const TextInput = ({ error, ...rest }) => (
  <div>
    <Input className={error && "error"} {...rest} />
    {error && <Error>{error}</Error>}
  </div>
)

export default TextInput
